export * from "./PollyClient";
export * from "./Polly";
export * from "./commands/DeleteLexiconCommand";
export * from "./commands/DescribeVoicesCommand";
export * from "./commands/GetLexiconCommand";
export * from "./commands/GetSpeechSynthesisTaskCommand";
export * from "./commands/ListLexiconsCommand";
export * from "./commands/ListSpeechSynthesisTasksCommand";
export * from "./pagination/ListSpeechSynthesisTasksPaginator";
export * from "./commands/PutLexiconCommand";
export * from "./commands/StartSpeechSynthesisTaskCommand";
export * from "./commands/SynthesizeSpeechCommand";
export * from "./pagination/Interfaces";
export * from "./models/index";
